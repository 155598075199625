import React, { useEffect, useState } from 'react';

import footerStyles from './Footer.module.css';
import textStyles from './theme/text.module.css';

import codeLoungeLogo from '../images/logo_png_Logos for dark backgrounds_Full_logo-horizontal-white.png';

const Footer = () => {
  const [lastModified, setLastModified] = useState<string>('not set');

  useEffect(() => {
    setLastModified(new Date(document.lastModified).toLocaleDateString());
  });

  return (
    <div className={`footer-container ${footerStyles.container}`}>
      <p className={`${textStyles.textLightSmall}`}>
        Send us an email:{' '}
        <a
          href="mailto:hackathon2019@usi.ch"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
        >
          hackathon2019@usi.ch
        </a>
      </p>
      <p className={`${textStyles.textLightSmall}`}>
        Use the email above to get an invite for joining us on{' '}
        <a
          href="https://usihackathon2019.slack.com"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
          target="_blank"
        >
          slack
        </a>
      </p>
      <div className={footerStyles.legalDocs}>
        <a
          href="/terms-of-participation"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
        >
          Terms of Participation
        </a>
        <span className={textStyles.textLightSmall}>|</span>
        <a
          href="/privacy-policy"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
        >
          Privacy and Personal Data Policy
        </a>
      </div>
      <p className={`${textStyles.textLightSmall}`}>
        last modified on {lastModified}
      </p>
      <div className={footerStyles.poweredByLogoLink}>
        <span className={`${textStyles.textLightSmall}`}>Powered by </span>
        <a href="https://codelounge.si.usi.ch" target="_blank">
          <img src={codeLoungeLogo} height="64px" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
