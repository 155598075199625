import React from 'react';

import ScrollLink from '../../components/ScrollLink';
import { ExternalLink, Text } from '../../components/theme/text';
import { IFaqData } from '../FAQ';

const trackPrizesFaq: IFaqData = {
  title: 'Track & Prizes',
  questions: [
    {
      question:
        'I read the hackathon is structured in 2 tracks: what does it mean?',
      answer:
        'Yes, the hackathon has two main tracks and one track for children (called mini-hackathon). The first track is the “code track” which is the traditional hackathon where participants write code to build tools and prototypes. The second track is the “data analysis track”, where coding skills are not necessary (although can be helpful) and the goal is to analyse the datasets provided in the hackathon (on traffic, parking, demographic data, etc.) and find interesting facts in it.'
    },
    {
      question:
        'Is there a winner for the hackathon? Does the winner win a prize?',
      answer: (
        <Text>
          Yes and yes. The hackathon is a competition and the teams which -
          according to the jury — build the best prototype or provide the best
          analysis will win a prize. In particular the awards will be given as
          stated in the <ScrollLink id="prizes">prizes table</ScrollLink>.
        </Text>
      )
    },
    {
      question: 'How are the projects and analyses evaluated?',
      answer: (
        <React.Fragment>
          <Text>
            At the end of the hackathon every team will have 3-5 minutes to
            present their project and give a demo of what they built (code
            track) or present the findings of their analysis (data analysis
            track). A jury of experts will evaluate the projects along different
            dimensions (e.g., novelty, business potential, social impact).
          </Text>
          <Text>
            The jury might also look at the source code and commit history, as
            well as which frameworks / tools have been used.
          </Text>
        </React.Fragment>
      )
    },
    {
      question:
        'I am neither a data scientist nor a developer, should I participate?',
      answer:
        'You totally should. A hackathon is all about getting creative and build something together, where a mixture of skills and experience is necessary, not limited to coding and analysis expertise. There are a number of ways to contribute, as for example a PM to make sure the team is progressing in the right direction, creation of sketches, UIs, graphics, and of course ideas.'
    },
    {
      question: 'I don’t know any participant, should I participate anyway?',
      answer: (
        <Text>
          Absolutely! This is your opportunity to meet awesome people and who
          knows, this might end up in a startup in the future. To help you find
          your teammates, we created a dedicated slack channel, where you can
          already look for potential teammates:{' '}
          <ExternalLink
            href="https://usihackathon2019.slack.com"
            target="_blank"
          >
            usihackathon2019.slack.com
          </ExternalLink>{' '}
          (send us an{' '}
          <ExternalLink href="mailto:hackathon2019@usi.ch">email</ExternalLink>{' '}
          to get an invite)
        </Text>
      )
    }
  ]
};

export default trackPrizesFaq;
