import React from 'react';
import {
  ExternalLink,
  Header1,
  Text,
  TextBold
} from '../components/theme/text';

import textStyles from '../components/theme/text.module.css';
import scheduleStyles from './Logistics.module.css';

const Logistics = () => (
  <div className={scheduleStyles.container} id="logistics">
    <Header1>Logistics</Header1>

    <div className={scheduleStyles.tableContainer}>
      <div id={scheduleStyles.friday}>
        <Text>
          <span className={textStyles.textBold}>Friday</span>
          <br />
          15/11
        </Text>
      </div>
      <Text id={scheduleStyles.openingTime}>18:00</Text>
      <div className={scheduleStyles.leftAlign} id={scheduleStyles.opening}>
        <TextBold>Opening</TextBold>
      </div>
      <Text id={scheduleStyles.aperoTime}>18:30</Text>
      <div className={scheduleStyles.leftAlign} id={scheduleStyles.apero}>
        <TextBold>Welcome apéro</TextBold>
      </div>
      <Text id={scheduleStyles.elasticTime}>19:30</Text>
      <div className={scheduleStyles.leftAlign} id={scheduleStyles.elastic}>
        <TextBold>
          Elasticsearch presentation and enablement session
          <br />
          <br />
          <em style={{ fontWeight: 'normal' }}>
            Come and learn how to use Elasticsearch the best and increase your
            chance to win the hackathon
          </em>
          <br />
          <br />
          IBM presentation of their cloud products, offered for free to the
          participants
          <br />
          <br />
          Participants start hacking
        </TextBold>
      </div>

      <hr className={scheduleStyles.dividerLine} id={scheduleStyles.divider1} />

      <div id={scheduleStyles.saturday}>
        <Text>
          <span className={textStyles.textBold}>Saturday</span>
          <br />
          16/11
        </Text>
      </div>
      <Text id={scheduleStyles.hackathonSaturdayTime}>all day</Text>
      <div
        className={scheduleStyles.leftAlign}
        id={scheduleStyles.hackathonSaturday}
      >
        <TextBold>USI Hackathon</TextBold>
      </div>
      <Text id={scheduleStyles.kidsTime}>10 - 16</Text>
      <div className={scheduleStyles.leftAlign} id={scheduleStyles.kids}>
        <TextBold>"Mini-Hackathon" (Ated4Kids)</TextBold>
      </div>

      <hr className={scheduleStyles.dividerLine} id={scheduleStyles.divider2} />

      <div id={scheduleStyles.sunday}>
        <Text>
          <span className={textStyles.textBold}>Sunday</span>
          <br />
          17/11
        </Text>
      </div>
      <Text id={scheduleStyles.hackathonSundayTime}>until 17</Text>
      <div
        className={scheduleStyles.leftAlign}
        id={scheduleStyles.hackathonSunday}
      >
        <TextBold>USI Hackathon</TextBold>
      </div>
      <Text id={scheduleStyles.closingTime}>17:00</Text>
      <div className={scheduleStyles.leftAlign} id={scheduleStyles.closing}>
        <TextBold>Award ceremony and closing apéro</TextBold>
      </div>
    </div>

    <div className={scheduleStyles.locationContainer}>
      <div className={scheduleStyles.mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1385.5070443999055!2d8.95579135822274!3d46.010911094777825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47842d8a7c4dde55%3A0x166125adf2a88850!2sMain%20Lecture%20Theatre%2C%206900%20Lugano!5e0!3m2!1sen!2sch!4v1571131105180!5m2!1sen!2sch"
          style={{ border: '0', width: '100%', height: '100%' }}
        />
      </div>
      <Text>
        USI Hackathon 2019 takes place in the{' '}
        <ExternalLink
          href="https://goo.gl/maps/XWXP6Uym5jUzJf1S9"
          target="__blank"
        >
          Aula Magna on the USI campus
        </ExternalLink>
      </Text>
      <Text>Via Buffi 13, 6900 Lugano, Svizzera</Text>
    </div>
  </div>
);

export default Logistics;
