import React from 'react';

import scrollLinkStyles from './ScrollLink.module.css';

interface IProps {
  id: string;
  className?: string;
}

const ScrollLink: React.SFC<IProps> = ({ id, children, className }) => {
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    const element = document.querySelector(`#${id}`);
    if (element) {
      const yCoordinate =
        element.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -60;

      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }
  };

  return (
    <span
      className={className || scrollLinkStyles.scrollLink}
      onClick={handleMenuItemClick}
    >
      {children}
    </span>
  );
};

export default ScrollLink;
