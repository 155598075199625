import React from 'react';

import { Header1, TextBold, TextSmall } from '../components/theme/text';

import juryStyles from './Jury.module.css';

import AndreaGiovanniniAvatar from '../images/jury/Andrea-Giovannini.png';
import CarloAlbertoFuriaAvatar from '../images/jury/CarloAlberto-Furia.jpg';
import DefaultAvatar from '../images/jury/default.svg';
import ElenaMarchioriAvatar from '../images/jury/Elena-Marchiori.jpg';
import ErnstWit from '../images/jury/Ernst-Wit.jpg';
import GiorgioMaricAvatar from '../images/jury/Giorgio-Maric.jpg';
import LorenzoSinigaglia from '../images/jury/Lorenzo-Sinigaglia.jpg';
import LucaCameriniAvatar from '../images/jury/Luca-Camerini.jpg';
import MarcoBizzantinoAvatar from '../images/jury/Marco-Bizzantino.jpg';
import MarcoFarinaAvatar from '../images/jury/Marco-Farina.png';
import MatthiasHauswirth from '../images/jury/Matthias-Hauswirth.jpg';
import PeterGruberAvatar from '../images/jury/Peter-Gruber.jpg';
import RyanVanninAvatar from '../images/jury/Ryan-Vannin.jpg';
import SiegfriedAlbertonAvatar from '../images/jury/Siegfried-Alberton.jpg';
import SilviaSantiniAvatar from '../images/jury/Silvia-Santini.jpg';
import TizianoLeidi from '../images/jury/Tiziano-Leidi.jpg';
import UmbertoBondiAvatar from '../images/jury/Umberto-Bondi.jpg';

interface IJuryMember {
  name: string;
  surname: string;
  role?: string;
  avatar?: string;
}

const juryMembers: IJuryMember[] = [
  {
    name: 'Carlo Alberto',
    surname: 'Furia',
    role: 'Associate Professor at Software Institute, USI',
    avatar: CarloAlbertoFuriaAvatar
  },

  {
    name: 'Matthias',
    surname: 'Hauswirth',
    role: 'Associate Professor at Software Institute, USI',
    avatar: MatthiasHauswirth
  },

  {
    name: 'Ernst',
    surname: 'Wit',
    role: 'Full Professor at Institute of Computational Science, USI',
    avatar: ErnstWit
  },

  {
    name: 'Peter',
    surname: 'Gruber',
    role: 'Lecturer and researcher at Faculty of Economics, USI',
    avatar: PeterGruberAvatar
  },

  {
    name: 'Umberto',
    surname: 'Bondi',
    role: 'Start-up Promotion Center (CP StartUp), USI',
    avatar: UmbertoBondiAvatar
  },

  {
    name: 'Elena',
    surname: 'Marchiori',
    role: 'Lugano Living Lab, Città di Lugano, and Lecturer, USI',
    avatar: ElenaMarchioriAvatar
  },

  {
    name: 'Giorgio',
    surname: 'Maric',
    role: 'Responsabile Servizio Statistica Urbana, Città di Lugano',
    avatar: GiorgioMaricAvatar
  },

  {
    name: 'Andrea',
    surname: 'Giovannini',
    role: 'AI Engineer & Lead Data Scientist, IBM Zurich Research Laboratory',
    avatar: AndreaGiovanniniAvatar
  },

  {
    name: 'Luca',
    surname: 'Camerini',
    role: 'CEO & Co-Founder, WellD',
    avatar: LucaCameriniAvatar
  },

  {
    name: 'Marco',
    surname: 'Farina',
    role: 'Founder & CEO, Logol',
    avatar: MarcoFarinaAvatar
  },

  {
    name: 'Marco',
    surname: 'Bizzantino',
    role: 'Owner - CTO and IT Superhero, Kiratech',
    avatar: MarcoBizzantinoAvatar
  },

  {
    name: 'Lorenzo',
    surname: 'Sinigaglia',
    role: 'Software Engineer, banana.ch',
    avatar: LorenzoSinigaglia
  },

  {
    name: 'Siegfried',
    surname: 'Alberton',
    role: 'Responsabile regionale FOCO, IUFFP',
    avatar: SiegfriedAlbertonAvatar
  },

  {
    name: 'Ryan',
    surname: 'Vannin',
    role: 'Lawyer, owner of Plastical and former organizer of Hack the City',
    avatar: RyanVanninAvatar
  },

  {
    name: 'Silvia',
    surname: 'Santini',
    role: 'Associate Professor at Faculty of Informatics, USI',
    avatar: SilviaSantiniAvatar
  },
  {
    name: 'Tiziano',
    surname: 'Leidi',
    role: 'Director at ISIN, SUPSI',
    avatar: TizianoLeidi
  }
];

const Jury = () => (
  <div id="jury" className={juryStyles.container}>
    <Header1>Jury</Header1>
    <div className={juryStyles.juryContainer}>
      {juryMembers
        .sort((a, b) => a.surname.localeCompare(b.surname))
        .map((member, index) => (
          <div key={index} className={juryStyles.juryMember}>
            <div
              style={{
                background: `url(${member.avatar ||
                  DefaultAvatar}) no-repeat center`,
                backgroundSize: 'cover'
              }}
              className={juryStyles.juryMemberPhoto}
            />
            <TextBold style={{ margin: 0 }}>
              {member.name} {member.surname}
            </TextBold>
            <TextSmall style={{ margin: 0 }}>{member.role}</TextSmall>
          </div>
        ))}
    </div>
  </div>
);

export default Jury;
