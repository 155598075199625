import React, { useRef, useState } from 'react';

import ScrollLink from './ScrollLink';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import menuStyles from './Menu.module.css';
import textStyles from './theme/text.module.css';

export interface IMenuItem {
  name: string;
  id: string;
}

const menuItems: IMenuItem[] = [
  { name: 'Winners', id: 'winners' },
  { name: 'About', id: 'about' },
  { name: 'Prizes', id: 'prizes' },
  { name: 'Jury', id: 'jury' },
  { name: 'FAQ', id: 'faq' },
  { name: 'Sponsors', id: 'sponsors' }
];

const Menu = () => {
  const myTopNavRef = useRef<HTMLDivElement>(null);

  const [isHidden, setIsHidden] = useState(true);

  const hiddenClass = isHidden ? menuStyles.hidden : '';

  const handleHamburgerClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsHidden(!isHidden);
  };

  return (
    <div className={menuStyles.container}>
      <div className={menuStyles.menu} id="myTopNav" ref={myTopNavRef}>
        <a
          className={`${menuStyles.icon} ${menuStyles.menuItem}`}
          onClick={handleHamburgerClick}
        >
          <FontAwesomeIcon icon={faBars} />
        </a>

        {menuItems.map((item, index) => (
          <ScrollLink
            key={index}
            className={`${textStyles.textLight} ${menuStyles.menuItem} ${hiddenClass}`}
            id={item.id}
          >
            {item.name}
          </ScrollLink>
        ))}
        <a
          href="/mini-hackathon"
          className={`${textStyles.textLight} ${menuStyles.menuItem} ${hiddenClass}`}
        >
          Mini-Hackathon
        </a>
      </div>
    </div>
  );
};

export default Menu;
