import React, { useState } from 'react';
import {
  ExternalLink,
  Header1,
  SubHeader,
  Text,
  TextBold,
  TextSmall,
  TextSmallBold
} from '../components/theme/text';

import ailLogo from '../images/data-providers/ail-logo.svg';
import hBenchmarkLogo from '../images/data-providers/H-Benchmark.png';
import societaNavigazioneLogo from '../images/data-providers/Logo SNL_ufficiale.png';
import meteoSuisseLogo from '../images/data-providers/meteo_suisse.jpg';
import publibikeLogo from '../images/data-providers/PubliBike_Logo_horizontal_RGB.jpg';
import cityLuganoLogo from '../images/data-providers/Stemma_LVGA_dicitura2righe_193U.svg';
import swisscomLogo from '../images/data-providers/Swisscom_Horizontal_RGB_Colour_Navy.svg';
import tplLogo from '../images/data-providers/TPL-logo-pos-A-CMYK.svg';
import tmnLogo from '../images/friends/Logo-tmn.png';
import ferroLogo from '../images/friends/Logo_ferro13_500x.png';
import masabaLogo from '../images/friends/Masaba-Coffee.png';
import unaVitaDiVinoLogo from '../images/friends/una_vita_di_vino.png';
import chooseLogo from '../images/sponsors/choose-logo.png';
import elasticLogo from '../images/sponsors/elastic-logo-H-full color.png';
import haslerLogo from '../images/sponsors/hasler-logo.svg';
import ibmLogo from '../images/sponsors/IBM_logoRR_blue60_RGB.gif';
import kiratechLogo from '../images/sponsors/kiratech-svizzera-logo_ok-01.png';
import bananaLogo from '../images/sponsors/logo-banana.svg';
import cpStartupLogo from '../images/sponsors/logo-cpstartup.svg';
import logolLogo from '../images/sponsors/logol-logo.png';
import swicketLogo from '../images/sponsors/swicket-logo.svg';
import tinextLogo from '../images/sponsors/Tinext-MCS.jpg';
import welldLogo from '../images/sponsors/welld_logo_rgb_payoff.svg';
import sponsorsStyles from './Sponsors.module.css';

const Sponsors = () => {
  const [modalIsOpen, setModalState] = useState(false);

  const handleModalClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setModalState(!modalIsOpen);
  };

  return (
    <div className={sponsorsStyles.container} id="sponsors">
      <Header1>Sponsors</Header1>

      <div className={sponsorsStyles.logoMainContainer}>
        <SubHeader>Gold</SubHeader>
        <div className={sponsorsStyles.logoContainer2}>
          <div className={sponsorsStyles.logo}>
            <img src={elasticLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={haslerLogo} />
          </div>
        </div>

        <SubHeader>Silver</SubHeader>
        <div className={sponsorsStyles.logoContainer2}>
          <div className={sponsorsStyles.logo}>
            <img src={chooseLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={ibmLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={logolLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={welldLogo} />
          </div>
        </div>
        <div className={sponsorsStyles.logoContainer1}>
          <div className={sponsorsStyles.logo}>
            <img src={cpStartupLogo} />
          </div>
        </div>

        <SubHeader>Bronze</SubHeader>
        <div className={sponsorsStyles.logoContainer3}>
          <div className={sponsorsStyles.logo}>
            <img src={bananaLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={kiratechLogo} style={{ marginTop: '15px' }} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={tinextLogo} />
          </div>
        </div>

        <SubHeader>👩‍🔧 Tech Sponsors 👨‍🔧</SubHeader>
        <div className={sponsorsStyles.logoContainer3}>
          <div className={sponsorsStyles.logo}>
            <img src={elasticLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={ibmLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={swicketLogo} />
          </div>
        </div>

        <SubHeader>💾 Data Providers 💾</SubHeader>
        <div className={sponsorsStyles.logoContainer1}>
          <div className={sponsorsStyles.logo}>
            <img src={cityLuganoLogo} />
          </div>
        </div>
        <div className={sponsorsStyles.logoContainer2}>
          <div className={sponsorsStyles.logo}>
            <img src={ailLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={swisscomLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={societaNavigazioneLogo} />
          </div>
          <div className={sponsorsStyles.logo} style={{ maxHeight: '90px' }}>
            <img src={tplLogo} />
          </div>
        </div>
        <div className={sponsorsStyles.logoContainer2}>
          <div className={sponsorsStyles.logo} style={{ maxHeight: '90px' }}>
            <img src={publibikeLogo} />
          </div>
          <div className={sponsorsStyles.logo} style={{ maxHeight: '90px' }}>
            <img src={meteoSuisseLogo} />
          </div>
        </div>
        <div className={sponsorsStyles.logoContainer1}>
          <div className={sponsorsStyles.logo} style={{ maxHeight: '90px' }}>
            <img src={hBenchmarkLogo} />
          </div>
        </div>

        <SubHeader>Friends</SubHeader>
        <div className={sponsorsStyles.logoContainer2}>
          <div className={sponsorsStyles.logo}>
            <img src={ferroLogo} />
          </div>

          <div className={sponsorsStyles.logo}>
            <img src={tmnLogo} />
          </div>
        </div>
        <div className={sponsorsStyles.logoContainer2}>
          <div className={sponsorsStyles.logo}>
            <img src={unaVitaDiVinoLogo} />
          </div>
          <div className={sponsorsStyles.logo}>
            <img src={masabaLogo} />
          </div>
        </div>
      </div>

      <Text>
        To join as a sponsor learn more{' '}
        <span className={sponsorsStyles.openModal} onClick={handleModalClick}>
          here
        </span>
      </Text>

      <div
        className={`${sponsorsStyles.modal} ${
          modalIsOpen ? '' : sponsorsStyles.hidden
        }`}
      >
        <div className={sponsorsStyles.modalContent}>
          <span className={sponsorsStyles.close} onClick={handleModalClick}>
            &times;
          </span>
          <Text>
            To join as a sponsor, you can choose one of the following options:
          </Text>
          <div className={sponsorsStyles.optionsTable}>
            <div className={sponsorsStyles.dummy} />
            <TextBold>Gold</TextBold>
            <TextBold>Silver</TextBold>
            <TextBold>Bronze</TextBold>

            <div
              className={`${sponsorsStyles.leftAlign} ${sponsorsStyles.section}`}
            >
              <TextSmallBold>
                Company logo on the website, flyer, and all the communication
                related to the event
              </TextSmallBold>
            </div>
            <TextSmall>placement in the first group</TextSmall>
            <TextSmall>placement in the second group</TextSmall>
            <TextSmall>placement in the third group</TextSmall>

            <div
              className={`${sponsorsStyles.leftAlign} ${sponsorsStyles.section}`}
            >
              <TextSmallBold>Visibility during the event</TextSmallBold>
            </div>
            <TextSmall>company stand in the refreshment area</TextSmall>
            <TextSmall>company flyers in the refreshment area</TextSmall>
            <TextSmall>-</TextSmall>

            <div
              className={`${sponsorsStyles.leftAlign} ${sponsorsStyles.section}`}
            >
              <TextSmallBold>
                Free entrance for the opening aperitivo and the award ceremony
              </TextSmallBold>
            </div>
            <TextSmall>5</TextSmall>
            <TextSmall>3</TextSmall>
            <TextSmall>1</TextSmall>

            <div
              className={`${sponsorsStyles.leftAlign} ${sponsorsStyles.section}`}
            >
              <TextSmallBold>
                Free participation in the competition
              </TextSmallBold>
            </div>
            <TextSmall>5</TextSmall>
            <TextSmall>3</TextSmall>
            <TextSmall>1</TextSmall>

            <div
              className={`${sponsorsStyles.leftAlign} ${sponsorsStyles.section}`}
            >
              <TextSmallBold>
                Company logo in the post-event video
              </TextSmallBold>
            </div>
            <TextSmall>yes</TextSmall>
            <TextSmall>no</TextSmall>
            <TextSmall>no</TextSmall>

            <div
              className={`${sponsorsStyles.rightAlign} ${sponsorsStyles.section}`}
            >
              <TextBold>Amount</TextBold>
            </div>
            <TextBold>5'000 CHF</TextBold>
            <TextBold>2'500 CHF</TextBold>
            <TextBold>1'000 CHF</TextBold>
          </div>
          <Text>
            Please get in touch!{' '}
            <ExternalLink href="mailto:hackathon2019@usi.ch">
              hackathon2019@usi.ch
            </ExternalLink>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
