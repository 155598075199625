import React from 'react';

import { ExternalLink, Text } from '../../components/theme/text';
import { IFaqData } from '../FAQ';

const teamsCommunicationFaq: IFaqData = {
  title: 'Teams & Communication',
  questions: [
    {
      question: 'Are there restrictions on the team size?',
      answer:
        'Team size should be between 2 and 6 people. Singleton team (of 1) are also allowed but not encouraged.'
    },
    {
      question: 'Do I need to be part of a team to participate?',
      answer: (
        <Text>
          No! If you don’t have a team already don’t worry. We will use the{' '}
          <ExternalLink
            href="https://usihackathon2019.slack.com"
            target="_blank"
          >
            slack workspace
          </ExternalLink>{' '}
          (send us an{' '}
          <ExternalLink href="mailto:hackathon2019@usi.ch">email</ExternalLink>{' '}
          to get an invite) before the event to compose teams and let other
          participants know about your interests and skills. Teams composition
          will be finalized on Friday evening.
        </Text>
      )
    },
    {
      question: 'Can I participate with my friends/colleagues?',
      answer:
        'Absolutely: you and your friends/colleagues can participate as a team, but please note that the hackathon can be an excellent opportunity to meet, work, and learn from new peers.'
    },
    {
      question:
        'How do I get logistic information during the event from the organizers?',
      answer: (
        <Text>
          You can use your favorite communication means. A dedicated{' '}
          <ExternalLink
            href="https://usihackathon2019.slack.com"
            target="_blank"
          >
            slack workspace
          </ExternalLink>{' '}
          (send us an{' '}
          <ExternalLink href="mailto:hackathon2019@usi.ch">email</ExternalLink>{' '}
          to get an invite) is also provided: this workspace will also be used
          by the organizers to communicate with all participants.
        </Text>
      )
    }
  ]
};

export default teamsCommunicationFaq;
