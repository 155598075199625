import React from 'react';

import { ExternalLink, Text } from '../../components/theme/text';

import faqStyles from './faq.module.css';

import { IFaqData } from '../FAQ';

const dataSourcesFaq: IFaqData = {
  title: 'Data Sources',
  questions: [
    {
      question:
        'Which data sources will be given to the hackathon participants?',
      answer: (
        <React.Fragment>
          <Text>
            The following data sources will be given to the participants:
          </Text>
          <ul>
            <li className={faqStyles.listItem}>
              <Text>From the City of Lugano and partners:</Text>
              <ul>
                <li className={faqStyles.listItem}>
                  Demographics of the City of Lugano, broken down by
                  neighborhood and borough; period 2008 to 2018
                </li>
                <li className={faqStyles.listItem}>
                  Hourly parking data of 10 parking garage in Lugano; period
                  2019-06-17 to 2019-10-11
                </li>
                <li className={faqStyles.listItem}>
                  Data about public transport from TPL
                </li>
                <li className={faqStyles.listItem}>
                  Bike sharing usage data from Publibike
                </li>
                <li className={faqStyles.listItem}>
                  Usage of boat transportation on the Lugano lake from Società
                  di Navigazione del Lago di Lugano
                </li>
                <li className={faqStyles.listItem}>
                  Transaction data from City of Lugano shops
                </li>
                <li className={faqStyles.listItem}>
                  Data from electric car recharging stations from AIL
                </li>
                <li className={faqStyles.listItem}>
                  Geographical location of TPL bus stops
                </li>
                <li className={faqStyles.listItem}>
                  Other sources are being evaluated (Swisscom data, hotel
                  occupancy information, historical meteo data)
                </li>
              </ul>
            </li>
            <li className={faqStyles.listItem}>
              <Text>
                We collected some open source datasets from{' '}
                <ExternalLink href="https://opendata.swiss/" target="_blank">
                  opendata.swiss
                </ExternalLink>{' '}
                which we believe would integrate nicely with the data provided
                by the City of Lugano and partners:
              </Text>
              <ul>
                <li className={faqStyles.listItem}>
                  Environmental air pollution parameters in Lugano (ozone,
                  nitrogen oxide, etc..), period 2018-10 to 2019-10
                </li>
                <li className={faqStyles.listItem}>Publibike usage data</li>
                <li className={faqStyles.listItem}>
                  Geographical location of public bike stations
                </li>
                <li className={faqStyles.listItem}>
                  Geographical location of electric car recharging stations
                </li>
              </ul>
            </li>
            <li className={faqStyles.listItem}>
              <Text>
                You are also more than welcome to integrate and use any other
                open source datasets (make sure to check their terms of usage).
                Here are a few links to get you started:
              </Text>
              <ul>
                <li className={faqStyles.listItem}>
                  <ExternalLink
                    href="https://opendata.swiss/en/"
                    target="_blank"
                  >
                    opendata.swiss
                  </ExternalLink>
                </li>
                <li className={faqStyles.listItem}>
                  <ExternalLink
                    href="https://www.kaggle.com/datasets"
                    target="_blank"
                  >
                    kaggle.com
                  </ExternalLink>
                </li>
                <li className={faqStyles.listItem}>
                  <ExternalLink
                    href="https://github.com/awesomedata/awesome-public-datasets"
                    target="_blank"
                  >
                    awesome-public-datasets
                  </ExternalLink>
                </li>
              </ul>
            </li>
          </ul>
        </React.Fragment>
      )
    }
  ]
};

export default dataSourcesFaq;
