import React from 'react';

import { Text } from '../../components/theme/text';
import { IFaqData } from '../FAQ';

const implementationDataFaq: IFaqData = {
  title: 'Implementation & Data',
  questions: [
    {
      question: 'Do I have to commit my code on GitHub?',
      answer:
        'It is requested that you version your code on a version control system, but it doesn’t need to be GitHub; other alternatives like GitLab or Bitbucket are totally fine. You should also grant access to the hackathon organizers so that they can look at the code and the commit history.'
    },
    {
      question:
        'Can I participate to the hackathon with a tool I’ve been building in the last couple of years?',
      answer: (
        <React.Fragment>
          <Text>
            No. To have a common baseline, development should start at the
            beginning of the hackathon. To this aim, the jury will look at the
            codebase and commit history and the data will be provided to the
            participants only at the beginning of the hackathon and not before.
          </Text>
          <Text>
            The teams can brainstorm before the start of the hackathon, but the
            production of any asset (code, design, media, etc.) should not start
            before the hackathon begins.
          </Text>
        </React.Fragment>
      )
    },
    {
      question: 'Can I use existing libraries / framework / tools?',
      answer:
        'Yes, please do not reinvent the wheel. That said, for fairness, the usage is limited to libraries/framework/tools that are available to all participants, i.e., you cannot use a library you created before that is not available to other hackathon participants.'
    },
    {
      question:
        'Who retain the ownership of the code produced during the hackathon?',
      answer: (
        <Text>
          You!! The ownership of the code is of the team which produces the code
          itself. Be careful not to include some pieces of the data provided by
          the City of Lugano and partners in your code, because that data can
          only be used during the hackathon and is regulated by the terms of
          service.
          {/* (see question <ScrollLink id="faq-">“Can I do whatever I want with the provided data?”</ScrollLink> */}
        </Text>
      )
    },
    {
      question:
        'Is it possible to win the competition with a brilliant idea and a superb slide deck?',
      answer:
        'No. The organizers and jury acknowledge the importance of the presentation and do value brilliant ideas; in fact, the better the idea/presentation the higher the chance of winning. That said, this is a hackathon, so there must be an implementation and such implementation must be done during the hackathon itself (not before).'
    }
  ]
};

export default implementationDataFaq;
