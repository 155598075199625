import React from 'react';

import {
  Header1Light,
  TextLight,
  TextLightBold
} from '../components/theme/text';

import prizesStyle from './Prizes.module.css';

const Prizes = () => {
  return (
    <div className={prizesStyle.background} id="prizes">
      <div className={prizesStyle.container}>
        <Header1Light>Prizes</Header1Light>
        <TextLight>
          The teams which build the best prototypes or provide the best analysis
          will win a 🏆!
        </TextLight>
        <Header1Light>🎉🎉🎉</Header1Light>
        <div className={prizesStyle.prizesTable}>
          <div />
          <TextLightBold id={prizesStyle.codingHeader}>
            Coding Track
          </TextLightBold>
          <TextLightBold id={prizesStyle.dataHeader}>
            Data Analysis Track
          </TextLightBold>

          <TextLight className={prizesStyle.medalText} id={prizesStyle.gold}>
            🥇
          </TextLight>
          <TextLight id={prizesStyle.goldCoding}>4'000 CHF</TextLight>
          <TextLight id={prizesStyle.goldData}>2'000 CHF</TextLight>

          <TextLight className={prizesStyle.medalText} id={prizesStyle.silver}>
            🥈
          </TextLight>
          <TextLight id={prizesStyle.silverCoding}>2'000 CHF</TextLight>
          <TextLight id={prizesStyle.silverData}>1'000 CHF</TextLight>

          <TextLight className={prizesStyle.medalText} id={prizesStyle.bronze}>
            🥉
          </TextLight>
          <TextLight id={prizesStyle.bronzeCoding}>1'000 CHF</TextLight>
        </div>
      </div>
    </div>
  );
};

export default Prizes;
