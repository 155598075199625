import React from 'react';

import { GatsbyLinkProps, Link } from 'gatsby';

import textStyles from './text.module.css';

export const Header1 = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) => (
  <h1 className={`header1 ${textStyles.header1}`} {...restProps}>
    {children}
  </h1>
);

export const SubHeader = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) => (
  <h3 className={`header1 ${textStyles.subHeader}`} {...restProps}>
    {children}
  </h3>
);

export const Header1Light = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) => (
  <h1 className={`header1-light ${textStyles.header1Light}`} {...restProps}>
    {children}
  </h1>
);

export const Text = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p className={`text ${textStyles.text}`} {...restProps}>
    {children}
  </p>
);

export const TextSmall = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p className={`text-small ${textStyles.textSmall}`} {...restProps}>
    {children}
  </p>
);

export const TextBold = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p className={`text-bold ${textStyles.textBold}`} {...restProps}>
    {children}
  </p>
);

export const TextSmallBold = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p className={`text-small-bold ${textStyles.textSmallBold}`} {...restProps}>
    {children}
  </p>
);

export const TextLight = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p className={`text-light ${textStyles.textLight}`} {...restProps}>
    {children}
  </p>
);

export const TextLightBold = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p className={`text-light ${textStyles.textLightBold}`} {...restProps}>
    {children}
  </p>
);

export const Emphasize = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) => (
  <span className={`text ${textStyles.textEmphasized}`} {...restProps}>
    {children}
  </span>
);

export const InternalLink = <TState extends unknown>(
  props: GatsbyLinkProps<TState>
) => {
  const { children, to } = props;

  return (
    <Link className={`link ${textStyles.link}`} to={to}>
      {children}
    </Link>
  );
};

export const ExternalLink = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a className={`link ${textStyles.link}`} {...restProps}>
    {children}
  </a>
);

export const ExternalLinkSubHeader = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a className={`link ${textStyles.linkSubHeader}`} {...restProps}>
    {children}
  </a>
);

export const ExternalLinkLight = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a className={`link ${textStyles.linkLight}`} {...restProps}>
    {children}
  </a>
);

export const LogoTitle = () => (
  <h1 className={`${textStyles.logoTitle}`}>USI Hackathon 2019</h1>
);

export const LogoSubTitle = () => (
  <h1 className={`${textStyles.logoSubtitle}`}>Making Data Alive</h1>
);
