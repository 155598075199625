import React, { useEffect, useState } from 'react';

import Container from '../components/theme/layout';
import { computeTimeRemaining, getCountdownStringFromTime } from '../util/time';
import splashStyles from './Splash.module.css';

const Splash = () => {
  const hackathonStartUTC = Date.UTC(2019, 10, 15, 18);
  const computeHackathonTimeRemaining = () => {
    return computeTimeRemaining(hackathonStartUTC);
  };

  const earlyBirdDeadlineUTC = Date.UTC(2019, 10, 11, 23, 59, 59);
  const computeEarlyBirdTimeRemaining = () => {
    return computeTimeRemaining(earlyBirdDeadlineUTC);
  };

  const [timeRemaining, setTimeRemaining] = useState(
    computeHackathonTimeRemaining()
  );
  const [earlyBirdTimeRemaining, earlyBirdSetTimeRemaining] = useState(
    computeEarlyBirdTimeRemaining()
  );

  useEffect(() => {
    setTimeout(() => {
      setTimeRemaining(computeHackathonTimeRemaining());
    }, 1000);
  }, [timeRemaining]);

  useEffect(() => {
    setTimeout(() => {
      earlyBirdSetTimeRemaining(computeEarlyBirdTimeRemaining());
    }, 1000);
  }, [earlyBirdTimeRemaining]);

  return (
    <div
      className={`splash-background-container ${splashStyles.splashBackgroundContainer}`}
    >
      <div className={`splash-container ${splashStyles.splashContainer}`}>
        <Container>
          <div
            className={`splash-text-container ${splashStyles.splashTextContainer}`}
          >
            <div
              className={`splash-logo-container ${splashStyles.splashLogoContainer}`}
            >
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                USI
              </span>
              <br />
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                HACKATHON
              </span>
              {[...Array(3)].map((_, index) => (
                <br key={index} />
              ))}
              <span
                className={`splash-logo-subtitle ${splashStyles.splashLogoSubTitle}`}
              >
                Making Data Alive
              </span>
            </div>
            <div className={`splash-logo-info ${splashStyles.splashLogoInfo}`}>
              November 15-17, 2019 | Lugano, Switzerland
              {/* <br />
              {timeRemaining > 0
                ? getCountdownStringFromTime(timeRemaining)
                : `🎉🎉🎉 hacking on  🎉🎉🎉`} */}
            </div>
            <div className={splashStyles.splashHackOver}>
              Hacking done!
              <br />
              Thanks to everyone <br />
              who made it possible!
            </div>
            {/* <div>
              <button
                type="submit"
                className={splashStyles.registerButton}
                disabled={true}
              >
                SOLD OUT
              </button>
              <a
                href="/hackathon-schedule.pdf"
                target="_blank"
                className={splashStyles.registerButton}
              >
                SCHEDULE
              </a>
            </div> */}
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center'
              }}
              className={`splash-logo-info ${splashStyles.splashLogoInfo}`}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Splash;
