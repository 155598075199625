import React from 'react';

import {
  Emphasize,
  ExternalLinkLight,
  TextLight
} from '../components/theme/text';

import ScrollLink from '../components/ScrollLink';
import textStyles from '../components/theme/text.module.css';
import aboutStyles from './About.module.css';

const About = () => (
  <div className={`about-background ${aboutStyles.background}`} id="about">
    <div className={`about-container ${aboutStyles.container}`}>
      <div className={`about-text-container ${aboutStyles.textContainer}`}>
        <TextLight>
          <ExternalLinkLight href="https://codelounge.si.usi.ch/">
            CodeLounge
          </ExternalLinkLight>
          , the center for software research & development of the{' '}
          <ExternalLinkLight href="https://www.si.usi.ch/">
            Software Institute
          </ExternalLinkLight>
          , is pleased to announce the USI Hackathon 2019, which will take place
          in the USI Aula Magna{' '}
          <Emphasize>from Friday (Nov 15) to Sunday (Nov 17)</Emphasize>.
        </TextLight>
        <TextLight>
          The USI Hackathon represents a first experiment in Lugano of an open
          data approach to share city-related data, in particular regarding city
          mobility flows. To this aim, a number of datasets concerning the city
          of Lugano and, in particular, its mobility, will be given to the
          participants. The City of Lugano will provide real data through the{' '}
          <ExternalLinkLight href="https://luganolivinglab.ch/">
            Lugano Living Lab
          </ExternalLinkLight>{' '}
          initiative.
        </TextLight>
        <TextLight>
          The goal of the USI Hackathon 2019 is to find interesting facts and
          trends in the data (<Emphasize>data analysis track</Emphasize>) and to
          leverage the data to build prototypes useful for citizens and tourists
          (<Emphasize>coding track</Emphasize>). In the coding track the teams
          with the best tool or prototype will be awarded, whereas in the data
          analysis track is all about producing the best data analysis. More
          details as well as examples of prototypes and analysis can be found in
          the{' '}
          <ScrollLink id="faq" className={textStyles.linkLight}>
            FAQ
          </ScrollLink>
          .
        </TextLight>
        <TextLight>
          Moreover, there will be a <Emphasize>“Mini-Hackathon”</Emphasize>, a
          special competition for kids and their families, will take place on
          Saturday (November 16) with an award ceremony at the end of the day.
        </TextLight>
      </div>
      <div
        className={`about-right-background-image ${aboutStyles.rightBackgroundImage}`}
      />
    </div>
  </div>
);

export default About;
