import React from 'react';

import Footer from './Footer';

import About from '../sections/About';
import FAQ from '../sections/FAQ';
import Jury from '../sections/Jury';
import Logistics from '../sections/Logistics';
import OrganizerLogos from '../sections/OrganizerLogos';
import Prizes from '../sections/Prizes';
import Splash from '../sections/Splash';
import Sponsors from '../sections/Sponsors';
import Winners from '../sections/Winners';
import Menu from './Menu';

const MainPage = () => (
  <div>
    <Menu />
    <Splash />
    <OrganizerLogos />
    <Winners />
    <About />
    <Logistics />
    <Prizes />
    <Jury />
    <FAQ />
    <Sponsors />
    <Footer />
  </div>
);

export default MainPage;
