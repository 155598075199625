import React, { ReactNode, useState } from 'react';

import { Header1, Text, TextBold } from '../components/theme/text';

import faqStyles from './FAQ.module.css';

import dataSourcesFaq from './faq/data-sources';
import goalsFaq from './faq/goals';
import implementationDataFaq from './faq/implementation-data';
import logisticsFoodFaq from './faq/logistics-food';
import teamsCommunicationFaq from './faq/teams-communication';
import trackPrizesFaq from './faq/track-prizes';

export interface IFaqQuestion {
  question: string;
  answer: string | ReactNode;
}
export interface IFaqData {
  title: string;
  questions: IFaqQuestion[];
}

const data: IFaqData[] = [
  goalsFaq,
  dataSourcesFaq,
  trackPrizesFaq,
  implementationDataFaq,
  teamsCommunicationFaq,
  logisticsFoodFaq
];

const FAQ = () => {
  const [hiddenState, setHiddenState] = useState(data.map(_ => true));

  return (
    <div className={faqStyles.container} id="faq">
      <Header1>FAQ</Header1>
      <div className={faqStyles.grid}>
        {data.map((section, index) => {
          const onSectionClick = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            event.preventDefault();
            const newHiddenState = [...hiddenState];
            newHiddenState[index] = !newHiddenState[index];
            setHiddenState(newHiddenState);
          };

          const hiddenClassName = `${
            hiddenState[index] ? faqStyles.hidden : ''
          }`;

          return (
            <React.Fragment key={index}>
              <div className={faqStyles.sectionHeader} onClick={onSectionClick}>
                <span>{section.title}</span>
                <span className={`${faqStyles.arrow} ${hiddenClassName}`}>
                  >
                </span>
              </div>
              <div
                className={`${faqStyles.questionsContainer} ${hiddenClassName}`}
              >
                {section.questions.map((question, qIndex) => (
                  <React.Fragment key={`${index}-${qIndex}`}>
                    <TextBold>{question.question}</TextBold>
                    {typeof question.answer === 'string' ? (
                      <Text>{question.answer}</Text>
                    ) : (
                      question.answer
                    )}
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default FAQ;
