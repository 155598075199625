export const getCountdownStringFromTime = (timeDistance: number) => {
  // based on https://www.w3schools.com/howto/howto_js_countdown.asp

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(timeDistance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export const computeTimeRemaining = (startTimeUTC: number) => {
  const now = new Date(Date.now());
  return (
    startTimeUTC -
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    )
  );
};
