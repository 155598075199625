import React from 'react';

import { Text } from '../../components/theme/text';

import { IFaqData } from '../FAQ';

const goalsFaq: IFaqData = {
  title: 'Goals',
  questions: [
    {
      question: 'What is the primary goal of the hackathon?',
      answer:
        'The USI Hackathon represents a first experiment in Lugano of an open data approach to share city-related data, in particular regarding city mobility flows. To this aim, a number of datasets concerning the city of Lugano and, in particular, its mobility, will be given to the participants. The goal of the hackathon is twofold: on the one hand to find interesting facts and trends in the data; on the other hand, to leverage the data to build prototypes useful for citizens and tourists. Both outcomes (data analysis and prototypes) can then be object to additional discussion with the City of Lugano and/or the data providers for potential implementation of the ideas emerged and/or further exploration of the results.'
    },
    {
      question: 'Are there additional goals for the hackathon?',
      answer: (
        <React.Fragment>
          <Text>
            Yes! With the hackathon we offer an excellent context for networking
            between professionals and university students. With this event we
            aim at increasing the amount of innovation, partnerships and
            entrepreneurship.
          </Text>
          <Text>
            Participants will also have the opportunity to learn technologies
            from our technical sponsors and to make an actual impact in future
            decisions of the city.
          </Text>
        </React.Fragment>
      )
    },
    {
      question:
        'What would be an example of a potential prototype? And of a data analysis?',
      answer: (
        <React.Fragment>
          <Text>
            By integrating historical information about hourly parking occupancy
            with bike sharing usage data one could build a web or mobile app
            that, given a target location, suggests the best parking + bike
            sharing station, based on average occupancy on that day of the
            week/hour. One could even integrate weather forecast and public
            transport data to suggest a bus in case likelihood of rain is high.
          </Text>
          <Text>
            In terms of data analysis, there are plenty of opportunities.
            Although datasets can be analyzed in isolation to find novel
            insights, we recommend to integrate multiple datasets in the
            analysis to discover interesting facts and correlation. For example,
            one could look at items sold by the city of Lugano related to
            parking viz-a-viz parking data. Another example would be to look at
            the distribution and correlation of payment types with demographics
            information.
          </Text>
        </React.Fragment>
      )
    }
  ]
};

export default goalsFaq;
