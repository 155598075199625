import React from 'react';

import {
  ExternalLink,
  ExternalLinkSubHeader,
  Header1,
  SubHeader,
  Text,
  TextBold,
  TextSmall
} from '../components/theme/text';

import { graphql, useStaticQuery } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';

import winnersStyles from './Winners.module.css';

import { WinnerImagesLookupQuery } from '../../types/graphql-types';

const Winners = () => {
  const data = useStaticQuery<WinnerImagesLookupQuery>(graphql`
    query WinnerImagesLookup {
      allFile(filter: { relativeDirectory: { eq: "winners" } }) {
        nodes {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
  `);

  const images = new Map(
    data.allFile.nodes.map(node => {
      const fluid = node.childImageSharp!.fixed;
      return [node.name, fluid] as [string, FixedObject];
    })
  );

  const Winner = (props: {
    imageName: string;
    team: string;
    members: string;
    flickrLink: string;
  }) => (
    <div>
      <a href={props.flickrLink} target="__blank">
        <Img
          fixed={images.get(props.imageName)}
          className={winnersStyles.winnersImage}
          imgStyle={{ objectFit: 'contain' }}
        />
      </a>
      <Text>{props.team}</Text>
      <TextSmall>{props.members}</TextSmall>
    </div>
  );

  return (
    <div id="winners" className={winnersStyles.container}>
      <Header1>Winners</Header1>
      <div id={winnersStyles.winnersContainer}>
        <div className={winnersStyles.trackWinnersContainer}>
          <SubHeader>Coding Track</SubHeader>
          <Winner
            imageName="winners-coding-1"
            team="🥇USJG"
            members="Gregory Wullimann, Umakanthan Kandiah, Simone Masiero and Jason
              Naldi"
            flickrLink="https://www.flickr.com/photos/185754239@N08/49137086676/in/album-72157711974256596/"
          />
          <Winner
            imageName="winners-coding-2"
            team="🥈not optional"
            members="Diego Carlino, Pasquale Polverino, Cristian Buratti and Alessandro
            Romanelli"
            flickrLink="https://www.flickr.com/photos/185754239@N08/49137283952/in/album-72157711974256596/"
          />
          <Winner
            imageName="winners-coding-3"
            team="🥉SleepNotFoundException"
            members="Aron Fiechter, Alexander Camenzind, Valerie Burgener, Federico
            Pfahler and Gustavo Graziani"
            flickrLink="https://www.flickr.com/photos/185754239@N08/49136596908/in/album-72157711974256596/"
          />
        </div>
        <div id={winnersStyles.trackSeparator} />
        <div className={winnersStyles.trackWinnersContainer}>
          <SubHeader>Data Analysis Track</SubHeader>
          <Winner
            imageName="winners-analysis-1"
            team="🥇Putin's Friends"
            members="Alexandre Batistella Bellas, Carmine D’Alessandro, Aida Gasanova,
            Alexandru Enache and Andrea Baron"
            flickrLink="https://www.flickr.com/photos/185754239@N08/49137086886/in/album-72157711974256596/"
          />
          <Winner
            imageName="winners-analysis-2"
            team="🥈Hackattoni"
            members="Marco Acquati, Francesco Puddu, Paolo Rizzo and Federico Rios"
            flickrLink="https://www.flickr.com/photos/185754239@N08/49136596708/in/album-72157711974256596/"
          />
        </div>
      </div>
      <div className={winnersStyles.usiScholarshipWinnersContainer}>
        <SubHeader>USI Master in Informatics Scholarship</SubHeader>
        <Winner
          imageName="winners-usi-scholarship"
          team="BikeMeThere"
          members="Giuseppe Mendola, Michele Damian, Amedeo Zucchetti, Andrea Aquino,
            Virginie Blancs, and Gabriele Prestifilippo"
          flickrLink="https://www.flickr.com/photos/185754239@N08/49136596078/in/album-72157711974256596/"
        />
      </div>
      <br />
      <SubHeader>
        For more photos check out our{' '}
        <ExternalLinkSubHeader
          href="https://www.flickr.com/photos/185754239@N08/albums/72157711974256596/with/49136596078/"
          target="__blank"
        >
          flickr album
        </ExternalLinkSubHeader>
      </SubHeader>
    </div>
  );
};

export default Winners;
