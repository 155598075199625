import React from 'react';

import { Text } from '../../components/theme/text';
import { IFaqData } from '../FAQ';

import faqStyles from './faq.module.css';

const logisticsFoodFaq: IFaqData = {
  title: 'Logistics & Food',
  questions: [
    {
      question: 'How do I register?',
      answer:
        'Please register online using our partner swicket (opens Monday October 21). You will then complete your registration on-site at the check-in desk, where you’ll also receive your badge and the awesome hackathon swag. The registration fee is 20 CHF until November the 11th and 40 CHF after (50 CHF onsite).'
    },
    {
      question: 'I did not register online, can I still participate?',
      answer:
        'Yes, if you come on Friday the 15th evening you can register directly onsite at the check-in desk. In this case the registration fee is 50 CHF.'
    },
    {
      question:
        'I have an important event on Saturday afternoon, can I still participate?',
      answer:
        // tslint:disable-next-line: quotemark
        "Of course. It is not mandatory that you participate to the hackathon for the entire 48 hours duration. Just make sure that (1) you coordinate with your team, and (2) someone from the team will give the presentation of your prototype/analysis on Sunday, otherwise you won't be able to win."
    },
    {
      question: 'Can I sleep at the hackathon location?',
      answer:
        'What do you mean sleep? You’re supposed to be coding! Seriously, you can sleep onsite: bring your own sleeping bag/mat and all necessary equipment. Please make sure to have your hackathon badge with you all the time, as the security personnel might be there every now and then.'
    },
    {
      question: 'How is the location organized?',
      answer:
        'The hackathon location (USI Aula Magna) is organized in two main areas: the aula magna itself where the hacking and presentation will take place, and the entrance area where the aperos will take place and where you’ll find the stands of our awesome sponsors, food, and drinks. Make sure to check out the sponsors’ stand to find out information about them and to collect swags!'
    },
    {
      question: 'Is food provided or should I bring my own food?',
      answer: (
        <React.Fragment>
          <Text>In terms of food, the hackathon is organized as follows:</Text>
          <ul>
            <li className={faqStyles.listItem}>
              Friday evening (opening) and Sunday evening (award ceremony):
              apero with finger food and drinks
            </li>
            <li className={faqStyles.listItem}>
              Saturday and Sunday: sandwiches will be available at lunch time
            </li>
            <li className={faqStyles.listItem}>
              Saturday night: the one and only hack-food: pizza
            </li>
          </ul>
          <Text>
            Water, soft drinks, redbull, and protein/power/energy bars will be
            available throughout the hackathon.
          </Text>
        </React.Fragment>
      )
    },
    {
      question: 'Can I bring my own food?',
      answer:
        'You are more than welcome to bring your own food. There will be a microwave oven in the room in case you need it.'
    }
  ]
};

export default logisticsFoodFaq;
